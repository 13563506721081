import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { LayoutService } from '@/services/layout-service'
import { DrawerItem } from '@/components/shared/Models/drawer-model'
import Commons from '@/components/shared/Helpers/commons'
import { AuthService } from '@/services/auth-service'
import { HourService } from '@/services/hour-service'
import { HeuresActivityResponseModel, HeuresCategoryModel, HeuresEntryResponseModel, HeuresReqModel, HeuresResponseModel } from '@/models/hours-model'
import { ErrorService } from '@/services/error.service'
import AgendaHelpers from '@/components/shared/Helpers/agenda-helpers'
import Alert from '@/components/shared/Alert.vue'
import { ValidationObserver } from 'vee-validate'
import HoursHelpers from '@/components/shared/Helpers/hours-helper'
import PatientAutoCompleteVue from '@/components/shared/PatientAutoComplete/PatientAutoComplete.vue'
import PatientAutoComplete from '@/components/shared/PatientAutoComplete/PatientAutoComplete'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    Alert,
    PatientAutoCompleteVue,
    ValidationObserver
  }
})
export default class Timbreuse extends Vue {
  private userService = AuthService.getInstance()
  private layoutService = LayoutService.getInstance()
  private hoursService = HourService.getInstance()

  public timbreuseDate = new Date().toISOString().substr(0, 10)
  public items: HeuresResponseModel = {
    hoursToDo: 0,
    pourcentage: 0,
    hoursPerDay: 0,
    totalHoursDoneForMonth: 0,
    totalHoursDoneForMonthStr: '',
    holidaysAllowancePerYear: 0,
    holidaysLeftToPlanForYear: 0,
    isValidated: false,

    holidaySummaryDate: '',
    holidaysPlanned: 0,
    holidaysTaken: 0,

    hasHolidayBalanceChange: false,

    activities: []
  }

  public showAlert = false
  public alertType: 'success' | 'error' = 'success'
  public alertMessages: string[] = []
  public selectedItem: HeuresEntryResponseModel|null = null
  public showConfirm = false

  public userFullName = ''
  public targetUserId?: string = undefined
  private ready = false
  private readyHours = false
  public isSaving = false
  public totalJour = '00:00'

  private profileNavItems: DrawerItem[] = [
    {
      icon: 'mdi-account-clock-outline',
      title: 'Saisie des heures',
      to: '/timbreuse'
    }
  ]

  public hours: number[] = []

  public minutes: {value: number; text: string}[] = []

  public hoursTable: HeuresCategoryModel[] = []

  public hoursOverviewTablesHeader = [
    { text: '', value: 'title' },
    { text: '', value: 'note' },
    { text: '', value: 'value' }
  ]

  public hoursOverviewTables: any[] = []
  public hasAccess = false

  public async mounted () {
    this.hasAccess = ModuleAuthorisationManager.HasAccess('timbreuse')

    if (this.$route.params.date) {
      this.timbreuseDate = new Date(this.$route.params.date as string).toISOString().substr(0, 10)
    }
    if (this.$route.params.userId) {
      this.targetUserId = this.$route.params.userId as string
    }

    this.hoursOverviewTables = []
    this.hours = []
    this.minutes = []
    for (let i = 0; i < 24; ++i) {
      this.hours.push(i)
    }
    for (let i = 0; i < 60; i += 5) {
      this.minutes.push({ value: i, text: `${i}` })
    }

    this.layoutService.updateDrawerList(this.profileNavItems)
    if (this.targetUserId) {
      await this.userService.getAllUsers().then(r => {
        const found = r.find(u => u.id.toLowerCase() === (this.targetUserId as string).toLowerCase())
        if (found) {
          this.userFullName = found.fullName
        }
      })
    } else {
      this.userFullName = this.userService.fullName
    }
    await this.hoursService.getHoursTable().then((r) => {
      this.hoursTable = r
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    })

    await this.refresh()
  }

  private updateReadyState () {
    this.ready = this.readyHours
  }

  @Watch('readyHours')
  public readyHoursChanged () {
    this.updateReadyState()
  }

  public onHoursChanged (e, category: HeuresCategoryModel, val: HeuresEntryResponseModel) {
    val.hours = e
    this.updateTotal(category, true)
  }

  public onMinutesChanged (e, category: HeuresCategoryModel, val: HeuresEntryResponseModel) {
    val.minutes = e
    this.updateTotal(category, true)
  }

  public setToHalfADay (val: HeuresEntryResponseModel, category: HeuresCategoryModel) {
    const totalInHours = this.items.hoursPerDay * this.items.pourcentage * 0.5
    const converted = Commons.HoursToHourMinute(totalInHours)
    val.hours = converted.fullHours
    val.minutes = converted.remainingMinutes
    val.isHalfDay = true
    val.isFullDay = false
    this.updateTotal(category, true)
  }

  public setToADay (val: HeuresEntryResponseModel, category: HeuresCategoryModel) {
    const totalInHours = this.items.hoursPerDay * this.items.pourcentage
    const converted = Commons.HoursToHourMinute(totalInHours)
    val.hours = converted.fullHours
    val.minutes = converted.remainingMinutes
    val.isHalfDay = false
    val.isFullDay = true
    this.updateTotal(category, true)
  }

  public unlockRow (val: HeuresEntryResponseModel, index: number, list: HeuresEntryResponseModel[]) {
    val.isHalfDay = false
    val.isFullDay = false
    this.$set(list, index, val)
  }

  private updateAlertSuccessMessage () {
    this.alertType = 'success'
    this.alertMessages = ["L'enregistrement des heures s'est déroulé avec succès"]
    this.showAlert = true
  }

  private updateAlertMessage (res: { errors: any[]; title: string }) {
    this.alertMessages = res.errors
    this.alertType = 'error'
    this.showAlert = res.errors.length > 0
  }

  public refreshOverview () {
    this.hoursOverviewTables = []
    this.hoursOverviewTables.push({
      title: 'Aperçu global des heures',
      rows: [
        {
          title: `Total ${new Date(this.timbreuseDate).toLocaleString('fr-CH', { year: 'numeric', month: 'long' })}`, // get month and year from timbreuseDate
          note: Commons.TransformDateFormat(this.timbreuseDate), // if month is over, get false
          value: `<span class="${HoursHelpers.getCssColorClass(this.items.totalHoursDoneForMonth, this.items.secondsToWorkForMonth ?? 0)}">${this.items.totalHoursDoneForMonthStr}</span> / ${this.items.hoursToWorkForMonthStr} h` // span color green if positive or 0, "primary" if negative
        },
        {
          title: 'Solde heure',
          note: Commons.TransformDateFormat(this.items.hoursBalanceDate),
          value: `<span class="${HoursHelpers.getCssColorClass(this.items.oldHoursBalance ?? 0, 0)}">${this.items.oldHoursBalanceStr}</span> h` // span color green if positive or 0, "primary" if negative
        },
        {
          title: 'Nouveau solde',
          note: Commons.TransformDateFormat(this.timbreuseDate),
          value: `<span class="${HoursHelpers.getCssColorClass(this.items.newHoursBalance ?? 0, 0)}">${this.items.newHoursBalanceStr}</span> h` // span color green if positive or 0, "primary" if negative
        }
      ]
    })
    this.hoursOverviewTables.push({
      title: `Vacances ${new Date(this.timbreuseDate).getUTCFullYear()}`, // get year from timbreuseDate
      rows: [
        {
          title: 'Jours pris', // get month and year from timbreuseDate var
          note: Commons.TransformDateFormat(this.items.holidaySummaryDate),
          value: `<span class="${HoursHelpers.getCssPrimaryOrNothing(this.items.holidaysTaken, this.items.holidaysAllowancePerYear ?? 0)}">${this.items.holidaysTaken}</span> / ${this.items.holidaysAllowancePerYear ?? 0}` // span color "primary" if more than total
        },
        {
          title: 'Jours prévus',
          note: Commons.TransformDateFormat(this.items.holidaySummaryDate),
          value: this.items.holidaysPlanned
        },
        {
          title: 'Jours restants à placer',
          note: Commons.TransformDateFormat(this.items.holidaySummaryDate),
          value: `<span class="${HoursHelpers.getCssPrimaryOrNothing(0, this.items.holidaysLeftToPlanForYear ?? 0)}">${this.items.holidaysLeftToPlanForYear ?? 0}</span>`, // span color "primary" if negative
          tooltip: true
        }
      ]
    })
  }

  private reInitPatientAutoComplete () {
    // re-init patient auto complete
    for (const prop in this.$refs) {
      if (Object.prototype.hasOwnProperty.call(this.$refs, prop)) {
        if (prop.startsWith('refpatient')) {
          const patientAutocomplete = this.$refs[prop][0] as PatientAutoComplete
          if (patientAutocomplete) {
            patientAutocomplete.init()
          }
        }
      }
    }
  }

  public async refresh () {
    this.readyHours = false
    this.hideAlert()
    this.hoursService.getUserHours(this.timbreuseDate, this.targetUserId).then((result) => {
      this.items = result
      // because patientsService.getPatients returns a string object in the backend side, as opposed to a Guid, we have to make this "conversion" here...
      // we could also change it in the backend but the chances of breaking something at this point are too high
      this.items.activities.forEach(a => {
        a.heures.forEach(h => {
          h.dossierId = h.dossierId?.toUpperCase()
        })
      })
      this.hoursTable.forEach(h => {
        this.updateTotal(h, false)
      })
      this.updateTotalJour()
      this.refreshOverview()
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    }).finally(() => {
      this.readyHours = true
      this.reInitPatientAutoComplete()
    })
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = observer && (await observer.validate())
    if (isValid) {
      this.isSaving = true
      const data: HeuresReqModel = { date: this.timbreuseDate, ...this.items }
      this.hoursService.updateUserHours(data, this.targetUserId).then(async () => {
        await this.refresh()
        this.updateAlertSuccessMessage()
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertMessage(res)
      }).finally(() => {
        this.isSaving = false
      })
    } else {
      const base = this.$refs
      let merged = this.$refs
      for (const prop in base) {
        if (Object.prototype.hasOwnProperty.call(base, prop)) {
          if (prop.startsWith('dureeG')) {
            if (base[prop].constructor === Array) {
              const dureeGObject = base[prop][0] as Vue
              if (dureeGObject) {
                merged = Object.assign(merged, (base[prop][0] as Vue).$refs)
              }
            } else {
              const dureeGObject = base[prop] as Vue
              if (dureeGObject) {
                merged = Object.assign(merged, (base[prop] as Vue).$refs)
              }
            }
          }
        }
      }
      Commons.focusFirstComponentWithError(observer, merged, '')
    }
  }

  public filterActivitiesByCategory (categoryId: number) {
    return this.items.activities.filter((a) => a.categoryId === categoryId)
  }

  public addHoursRow (row: HeuresActivityResponseModel, lastInput: string, categoryId: number, activityId: number) {
    row.heures.push({
      id: 0,
      hours: undefined,
      minutes: undefined,
      isAutomatic: false,
      dossierId: undefined
    })
    // for holidays we don't focus because there are no text fields, just the 2 icon buttons for half day and full day
    if (activityId !== 27) {
      this.$nextTick(() => {
        const componentRef = categoryId === 11 ? `${lastInput}-h` : `${lastInput}-ch`
        Commons.focusComponent(this.$refs[componentRef])
      })
    }
  }

  public deleteHoursRow (row: HeuresActivityResponseModel, index: number, category: HeuresCategoryModel) {
    row.heures.splice(index, 1)
    this.updateTotal(category, true)
    this.reInitPatientAutoComplete()
  }

  private totalForActivities (activities: HeuresActivityResponseModel[]) {
    let totalInMinutes = 0
    activities.forEach(a => {
      a.heures.forEach(h => {
        if (h.hours !== undefined && h.minutes !== undefined) {
          totalInMinutes += Number(h.minutes) + Number(h.hours) * 60
        }
      })
    })
    const fullHours = Math.trunc(totalInMinutes / 60)
    const remainingMinutes = Math.round(totalInMinutes - fullHours * 60)
    if (isNaN(fullHours) || isNaN(remainingMinutes)) {
      return '00:00'
    }
    const res = `${Commons.padWithZeros(fullHours, 2)}:${Commons.padWithZeros(remainingMinutes, 2)}`
    return res
  }

  public updateTotal (category: HeuresCategoryModel, updateTotalJour: boolean) {
    if (category) {
      const activities = this.items.activities.filter(a => a.categoryId === category.id)
      category.total = this.totalForActivities(activities)
    }
    if (updateTotalJour) {
      this.updateTotalJour()
    }
  }

  public updateTotalJour () {
    this.totalJour = this.totalForActivities(this.items.activities)
  }

  public get getTotalToDo () {
    return Commons.HoursToHourMinuteString(this.items.hoursToDo)
  }

  public get totalJourClass () {
    const totalJour = AgendaHelpers.ExtractHours(this.totalJour, true)
    const totalToDo = AgendaHelpers.ExtractHours(this.getTotalToDo, true)

    const totalJourInMinutes = totalJour.hours * 60 + totalJour.minutes
    const totalToDoInMinutes = totalToDo.hours * 60 + totalToDo.minutes
    return HoursHelpers.getCssColorClass(totalJourInMinutes, totalToDoInMinutes)
  }

  public hideAlert () {
    this.alertMessages = []
    this.showAlert = false
  }
}
