import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { ErrorService } from '@/services/error.service'
import { PatientService } from '@/services/patient-service'
import Commons from '../Helpers/commons'

@Component({
  components: {
  }
})
export default class PatientAutoComplete extends Vue {
  @Prop({ default: '' }) public value!: string
  @Prop({ default: '' }) public initialPatientCode!: number
  @Prop() public id!: string
  @Prop({ default: undefined }) public vid!: string | undefined

  private patientsService = PatientService.getInstance()

  public dbDossiers: dossierPatientModel[] = []
  public searchPatient = ''
  public selectedPatient: dossierPatientModel|null = null
  public selectedPatientId = ''
  public readyPatients = true

  public init () {
    if (this.initialPatientCode > 0) {
      this.getInitialPatient(this.initialPatientCode)
    }
  }

  @Watch('selectedPatientId') onChange (guid: string) {
    this.$emit('input', guid)
  }

  @Watch('value') onValueChanges (val: string) {
    if (val !== this.selectedPatient?.guid) {
      this.selectedPatient = this.dbDossiers.find(g => g.guid.toLowerCase() === val?.toLowerCase()) ?? null
      this.selectedPatientId = this.selectedPatient?.guid ?? ''
    }
  }

  @Watch('searchPatient')
  public searchPatientChanged (v) {
    if (v && v.length > 2) {
      this.refreshPatients()
    } else {
      this.dbDossiers = []
    }
  }

  public get patients () {
    if (this.selectedPatient?.fullName) {
      return [...this.dbDossiers, this.selectedPatient].sort((a, b) => (a.fullName.toLocaleLowerCase()).localeCompare(b.fullName.toLocaleLowerCase()))
    }
    return this.dbDossiers
  }

  public refreshPatients () {
    // bail early if a search is already in progress
    if (!this.readyPatients) {
      return
    }
    this.readyPatients = false
    this.patientsService.getActivePatients(this.searchPatient).then((ds: any) => {
      this.dbDossiers = ds.value
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.$emit('error', res.errors)
    }).finally(() => {
      this.readyPatients = true
    })
  }

  public getInitialPatient (patientCode: number) {
    // bail early if a search is already in progress
    if (!this.readyPatients) {
      return
    }
    this.readyPatients = false
    this.patientsService.getInitialPatient(patientCode).then((ds: any) => {
      this.dbDossiers = ds.value
      if (this.dbDossiers.length > 0) {
        this.selectedPatient = ds.value[0]
        this.selectedPatientId = this.selectedPatient?.guid ?? ''
      }
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.$emit('error', res.errors)
    }).finally(() => {
      // this is an optimization, we can also just do `this.readyPatients = true`
      // without the this.$nextTick but then it will trigger the watcher on `selectedPatientId`
      // and then call refreshPatients (for nothing)
      this.$nextTick(() => {
        this.readyPatients = true
      })
    })
  }

  public displayPatient (item: dossierPatientModel) {
    return Commons.autocompleteDisplayPatient(item, this.searchPatient)
  }
}
